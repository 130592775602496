/***POST***/
.postWrapper{
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  margin: 30px ;
  padding: ;
  margin-top: 150px;
  word-break: break-all;
}

@media only screen and (min-width: 821px) {

  .postOne{
    grid-column: 1 / 3;
    grid-row: 1;
    margin: 20px ;
    padding: ;
  }

  .postTwo{
    grid-column: 3 / 9;
    grid-row: 1;
    margin: ;
    padding: ;
  }

  .postThree{
    grid-column: 7 / 9;
    grid-row: 1;
    margin: ;
    padding: ;
  }

  .postFour{
    grid-column: 1 / 9;
    grid-row: 2;
    margin: ;
    padding: ;
  }

}

/*@media only screen and (min-width: 281px) and (max-width: 911px) {*/
@media only screen and (max-width: 820px) {

  .postOne{
    grid-column: 1 / 9;
    grid-row: 2;
    margin: 20px ;
    padding: ;
  }

  .postTwo{
    grid-column: 1 / 9;
    grid-row: 1;
    margin: ;
    padding: ;
  }

  .postThree{
    grid-column: 1/ 9;
    grid-row: 3;
    margin: ;
    padding: ;
  }

  .postFour{
    grid-column: 1 / 9;
    grid-row: 4;
    margin: ;
    padding: ;
  }

}

@media only screen and (max-width: 767px) {

  .postFrame{
    width:225px;
    height:127px;
  }

}

@media only screen and (min-width: 768px) {

  .postFrame{
    width:400px;
    height:225px;
  }

}

/***POST END***/
