/***POST LIST***/
.postListWrapper{
  word-break: break-all;
}

@media only screen and (max-width: 280px) {

  .postListWrapper{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    margin: 30px ;
    padding: ;
    margin-top: 350px;
  }

  .postListOne{
    grid-column: 1 / 9;
    grid-row: 2;
    margin: 20px ;
    padding: ;
  }

  .postListTwo{
    grid-column: 1 / 9;
    grid-row: 1;
    margin: ;
    padding: ;
    cursor: pointer;
  }

  .postListThree{
    grid-column: 9 / 9;
    grid-row: 2;
    margin: ;
    padding: ;
  }

  .postListFour{
    grid-column: 1 / 9;
    grid-row: 3;
    margin: ;
    padding: ;
  }

  .postListFive{
    grid-column: 1 / 9;
    grid-row: 4;
    margin: ;
    padding: ;
  }

}

@media only screen and (min-width: 281px) and (max-width: 911px) {

  .postListWrapper{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    margin: 30px ;
    padding: ;
    margin-top: 350px;
  }

  .postListOne{
    grid-column: 1 / 9;
    grid-row: 2;
    margin: 20px ;
    padding: ;
  }

  .postListTwo{
    grid-column: 1 / 9;
    grid-row: 1;
    margin: ;
    padding: ;
    cursor: pointer;
  }

  .postListThree{
    grid-column: 9 / 9;
    grid-row: 1;
    margin: ;
    padding: ;
  }

  .postListFour{
    grid-column: 1 / 9;
    grid-row: 3;
    margin: ;
    padding: ;
  }

  .postListFive{
    grid-column: 1 / 9;
    grid-row: 4;
    margin: ;
    padding: ;
  }

}

@media only screen and (min-width: 912px) {

  .postListWrapper{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    margin: 30px ;
    padding: ;
    margin-top: 150px;
  }

  .postListOne{
    grid-column: 1 / 3;
    grid-row: 1;
    margin: 20px ;
    padding: ;
  }

  .postListTwo{
    grid-column: 3 / 9;
    grid-row: 1;
    margin: ;
    padding: ;
    cursor: pointer;
  }

  .postListThree{
    grid-column: 7 / 9;
    grid-row: 1;
    margin: ;
    padding: ;
  }

  .postListFour{
    grid-column: 1 / 9;
    grid-row: 2;
    margin: ;
    padding: ;
  }

  .postListFive{
    grid-column: 1 / 9;
    grid-row: 3;
    margin: ;
    padding: ;
  }

}

@media only screen and (max-width: 767px) {

  .postListFrame{
    width:200px;
    height:113px;
  }

}

@media only screen and (min-width: 768px) {

  .postListFrame{
    width:450px;
    height:253px;
  }

}

/***POST END LIST***/
