/****FRONT PAGE****/

.frontPageWrapper {
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  padding: 20px;
  word-break: break-all;
}

#map {
  width: 90%;
  height: 100%;
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {

  .frontPageOne {
    grid-column: 1 / 4;
    grid-row: 2;
    cursor: pointer;
  }
  .frontPageTwo {
    grid-column: 1 / 4;
    grid-row: 1;
    text-align: center;
  }

}

@media only screen and (min-width: 768px) {

  .frontPageOne {
    grid-column: 3 / 4;
    grid-row: 1;
    cursor: pointer;
  }
  .frontPageTwo {
    grid-column: 1 / 3;
    grid-row: 1;
    text-align: center;
  }

}

/****FRONT PAGE END****/
