.profilePageContainer{
  word-break: break-all;
}

@media only screen and (min-width: 768px) {

  .profilePageContainer{

  }

  .profileInfo{

  }

  .profilePosts{

  }

  .profilePostWrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    padding: 20px;
    margin-top: 50px;
  }

  .profilePostsInfo{
    grid-column: 1 / 2;
    grid-row: 1;
  }

  .profilePostsMedia{
    grid-column: 2 / 4;
    grid-row: 1;
  }

  .profilePostsFooter{
    grid-column: 1 / 4;
    grid-row: 2;
  }

  .profilePostFrame{
    width:500px;
    height:281px;
  }

}

@media only screen and (max-width: 767px) {

  .profilePageContainer{

  }

  .profileInfo{

  }

  .profilePosts{

  }

  .profilePostWrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    padding: 20px;
    margin-top: 50px;
  }

  .profilePostsInfo{
    grid-column: 1 / 4;
    grid-row: 2;
  }

  .profilePostsMedia{
    grid-column: 1 / 4;
    grid-row: 1;
  }

  .profilePostsFooter{
    grid-column: 1 / 4;
    grid-row: 3;
  }

  .profilePostFrame{
    width:225px;
    height:127px;
  }

}
