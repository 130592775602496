html{
  scroll-behavior: smooth;
}
  body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding:0;
}

/*
  GENERAL BODY, TOOL TIPS, BUTTONS, RESPONSIVE WRAPPERS & RANDOM MARGIN TOPS
*/

.ytToolTip, .postToolTip{
  border-bottom: 1px dotted #000000;
  color: #000000; outline: none;
	cursor: help;
  text-decoration: none;
	position: relative;
}

.ytToolTip span, .postToolTip span {
	margin-left: -999em;
	position: absolute;
}

@media only screen and (max-width: 767px) {

  .ytToolTip:hover span, .ytToolTip:active span {
  	border-radius: 5px 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;
  	font-family: Calibri, Tahoma, Geneva, sans-serif;
    position: absolute; left: -.5em; top: 1.5em; z-index: 99;
    /*if small ipad screen left: -7em*/
  	margin-left: 0; width: 260px;
  }

  .classic { padding: .5em; font-size: .93em }

  .postToolTip:hover span, .postToolTip:active span {
  	border-radius: 5px 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;
  	font-family: Calibri, Tahoma, Geneva, sans-serif;
    position: absolute; left: -.5em; top: 1.5em; z-index: 99;
    /*if small ipad screen left: -7em*/
  	margin-left: -30px; width: 260px;
  }

}

@media only screen and (min-width: 768px) {

  .ytToolTip:hover span, .ytToolTip:active span {
  	border-radius: 5px 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;
  	font-family: Calibri, Tahoma, Geneva, sans-serif;
    position: absolute; left: -.5em; top: 1.5em; z-index: 99;
    /*if small ipad screen left: -7em*/
  	margin-left: 0; width: 280px;
  }

  .classic { padding: .5em; font-size: 1em }

  .postToolTip:hover span, .postToolTip:active span {
  	border-radius: 5px 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;
  	font-family: Calibri, Tahoma, Geneva, sans-serif;
    position: absolute; left: -7em; top: 2.5em; z-index: 99;
    /*if small ipad screen left: -7em*/
  	margin-left: -25px; width: 280px;
  }

}

.ytToolTip:hover em, .ytToolTip:active em, .postToolTip:hover em, .postToolTip:active em {
	font-family: Candara, Tahoma, Geneva, sans-serif;
  font-size: 1.2em;
  font-weight: bold;
	display: block;
  padding: 0.2em 0 0.6em 0;
}
.custom { padding: 0.5em 0.8em 0.8em 2em; }
* html a:hover { background: transparent; }
.classic {background: white; border: 0px solid; }
.critical { background: #FFCCAA; border: 1px solid #FF3334;	}
.help { background: #9FDAEE; border: 1px solid #2BB0D7;	}
.info { background: #9FDAEE; border: 1px solid #2BB0D7;	}
.warning { background: #FFFFAA; border: 1px solid #FFAD33; }

/****/

button{
  cursor: pointer;
}

/*Forms*/

.mapButton{
  height: 50px;
  font-family: 'Overpass', sans-serif;
  font-weight: bolder;
  font-size: 1.5em;
  padding:10px;
  color: white;
  background: #0F398B;
  border-radius: 5px;
  cursor: pointer;
}

.registerButton{
  height: 50px;
  font-family: 'Overpass', sans-serif;
  font-weight: bolder;
  font-size: 1.3em;
  padding:10px;
  background: #01735C;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.loginButton{
  height: 40px;
  padding:10px;
  background: #01735C;
  font-family: 'Overpass', sans-serif;
  font-weight: bolder;
  font-size: 1.1em;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.logoutButton{
  height: 40px;
  font-family: 'Overpass', sans-serif;
  font-weight: bolder;
  font-size: 1.1em;
  padding:10px;
  background: white;
  border-radius: 5px;
  cursor: pointer;
}

.addCommentButton{
  height: 40px;
  font-family: 'Overpass', sans-serif;
  font-weight: bolder;
  font-size: 1em;
  padding:10px;
  background: #ECE81A;
  border-radius: 5px;
  cursor: pointer;
}

.deleteCommentButton{
  height: 40px;
  font-family: 'Overpass', sans-serif;
  font-weight: bolder;
  font-size: 1em;
  padding:10px;
  background: #CC0202;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {

  body{
    background: lightgrey;
    list-style: none;
    text-align: center;
  }

  .no-scroll{
    overflow-y: hidden;
  }

  .unlikeBttn{
    opacity: .5;
    text-align: center;
  }

  .unlikeBttn svg, .likeBttn svg{
    font-size: 55px;
  }

  .likeBttn{
    opacity: 1;
    text-align: center;
  }

  .likedPost svg{
    opacity: 1;
    color: green;
  }

  /*****/

  .mobileView{
    display: grid;
  }

  .desktopView{
    display: none;
  }

}

@media only screen and (min-width: 768px) {

  .mobileView{
    display: none;
  }

  .desktopView{
    display: grid;
  }

  body{
    background: lightgrey;
    list-style: none;
  }

  .no-scroll{
    overflow-y: hidden;
  }

  .unlikeBttn{
    opacity: .5;
    text-align: center;
  }

  .unlikeBttn svg, .likeBttn svg{
    font-size: 55px;
  }

  .likeBttn{
    opacity: 1;
    text-align: center;
  }

  .likedPost svg{
    opacity: 1;
    color: green;
  }

}

/**********/
@media only screen and (max-width: 767px) {

  .password{
    margin-top: 150px;
  }

  .pageNotFound{
    margin-top: 150px;
  }

  .register{
    margin-top: 150px;
    text-align: ;
  }

  .add{
    margin-top: 150px;
    text-align: left;
  }

  .profilePageContainer{
    margin-top: 150px;
  }

}

@media only screen and (min-width: 768px) {

  .password{
    margin-top: 150px;
  }

  .pageNotFound{
    margin-top: 150px;
  }

  .register{
    margin-top: 150px;
    text-align: ;
  }

  .add{
    margin-top: 150px;
    text-align: left;
  }

  .profilePageContainer{
    margin-top: 150px;
  }

}

/*
  GENERAL BODY, TOOL TIPS, BUTTONS, RESPONSIVE WRAPPERS & RANDOM MARGIN TOPS
  ***END***
*/
