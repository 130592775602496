@media only screen and (max-width: 767px) {

  /*****/

  .mobileNavigationWrapper{
    width: 100%;
    position: fixed;
    z-index: 2000;
    background-color: lightgrey;
    margin-top: -10px;
    margin-left: -10px;
  }

  .burgerNav svg {
    font-size: 40px;
    color: ;
    margin-left: -90%;
    cursor: pointer;
  }

}
