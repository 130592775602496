.search {
 z-index: 1000;
 position: absolute;
}

.searchInputs {
  margin-top: 10px;
  display: flex;
}

.searchIcon {
  height: 60px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 30px;
}

.dataResult {
  margin-top: -1px;
  /*width: 280px;*/
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  /*display: none;*/
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}

a:hover {
  background-color: lightgrey;
}

#clearBtn {
  cursor: pointer;
}

@media only screen and (max-width: 280px) {

  .search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 75vw;
  }

}

@media only screen and (min-width: 281px) and (max-width: 538px) {

  .search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 83vw;
  }

}

@media only screen and (min-width: 415px) and (max-width: 767px) {

  .search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 87vw;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1279px){

  .search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 200px;
  }

}

@media only screen and (min-width: 1280px) {

  .search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 300px;
  }

  .dataResult {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {

  .dataResult {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {

  .dataResult {
    width: 280px;
  }
}

@media only screen and (min-width: 1280px) {

  .dataResult {
    width: 380px;
  }

}
