/***LOCATION***/

.locationHeader {
  text-align: left;
}

.locationNameDisplay{
  word-break: break-all;
}

.locationDescDisplay{
  word-break: break-all;
}

@media only screen and (max-width: 911px) {

  .locationFrame{
    width:300px;
    height:169px;
  }

}

@media only screen and (min-width: 912px) {

  .locationFrame{
    width:400px;
    height:225px;
  }

}

@media only screen and (max-width: 767px) {

  .locationHeader{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    margin: 30px ;
    padding: ;
    margin-top: 125px;
  }

  .locationDescription{
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .editLocation{
    grid-column:  1 / 9;
    grid-row: 2;
    text-align: left;
  }

  .addPost{
    grid-column: 1 / 9;
    grid-row: 3;
    text-align: left;
  }

}

@media only screen and (min-width: 768px) {

  .locationHeader{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    margin: 30px ;
    padding: ;
    margin-top: 150px;
  }

  .locationDescription{
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .editLocation{
    grid-column:  3 / 6;
    grid-row: 1;
  }

  .addPost{
    grid-column: 7 / 9;
    grid-row: 1;
  }

}

/***LOCATION END***/
