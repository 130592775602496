/*****NAVIGATION*****/
.policyLinksHolder {

}

.policyLinksIcon SVG{
  color: black;
  font-size: 40px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {

  .navigationWrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    width: 100%;
    position: fixed;
    z-index: 2000;
    background-color: lightgrey;
    margin-top: -10px;
    margin-left: -10px;
    opacity: .95;
  }

  .navigationLogo{
    grid-column: 1 / 1;
    grid-row: 1;
    display: none;
  }

  .navigatonLogo li{
    display: inline;
  }

  #label{
    font-size: 35px;
    font-weight: 700;
    margin: 10px;
  }

  .label {
    font-size: 25px;
    font-weight: 700;
  }

  .navigationSearch{
    grid-column: 1 / 4;
    grid-row: 3;
  }

  .navigationLinks{
    grid-column: 1 / 4;
    grid-row: 1;
    margin-top: 50px;
  }

  .navigationUser{
    grid-column: 1 / 4;
    grid-row: 2;
  }

  .links li{
    display: inline;
    margin: 5px;
  }

  .navigationButton{
    height: 50px;
    font-weight: bolder;
    font-size: 1.5em;
    padding:10px;
    background: white;
    border-radius: 5px;
  }

  .policyLinks {
    margin-left: ;
    margin-top: -10px;
    margin-bottom: 10px;
    background: white;
    padding: 10px;
    z-index: 600;
    width: 100vw;
    opacity:1;
    border-radius: 5px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 912px) {

  .navigationWrapper{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    width: 100%;
    position: fixed;
    z-index: 2000;
    background-color: lightgrey;
    margin-top: -10px;
    margin-left: -10px;
    opacity: .95;
  }

  .navigationLogo{
    grid-column: 1 / 1;
    grid-row: 1;
  }

  .navigationLogo img{
    margin-left: 5px;
  }

  .navigationLogo li{
    display: inline;
  }

  #label{
    font-size: 35px;
    font-weight: 700;
    margin: 10px;
  }

  .label {
    font-size: 25px;
    font-weight: 700;
  }

  .navigationSearch{
    grid-column: 2 / 6;
    grid-row: 1;
    margin-left: 20px;
  }

  .navigationLinks{
    grid-column: 6 / 8;
    grid-row: 1;
    margin-left: 30px;
  }

  .navigationUser{
    grid-column: 8 / 9;
    grid-row: 1;
    margin-right: 20px;
  }

  .links li{
    display: inline;
    margin: 5px;
  }

  #login{
    margin-top: 15px;
  }

  #mapModal{
    margin-top: 15px;
  }

  .navigationButton{
    height: 50px;
    font-weight: bolder;
    font-size: 1.5em;
    padding:10px;
    background: white;
    border-radius: 5px;
  }

  .policyLinks {
    margin-left: -50px;
    margin-top: -10px;
    margin-bottom: 10px;
    background: white;
    padding: 9px;
    z-index: 600;
    position: absolute;
    opacity:1;
    border-radius: 5px;
  }

}

@media only screen and (min-width: 913px) {

  .navigationWrapper{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    width: 100%;
    position: fixed;
    z-index: 2000;
    background-color: lightgrey;
    margin-top: -10px;
    margin-left: -10px;
    opacity: .95;
  }

  .navigationLogo{
    grid-column: 1 / 1;
    grid-row: 1;
  }

  .navigationLogo img{
    margin-left: 5px;
  }

  .navigationLogo li{
    display: inline;
  }

  #label{
    font-size: 35px;
    font-weight: 700;
    margin: 10px;
  }

  .label {
    font-size: 25px;
    font-weight: 700;
  }

  .navigationSearch{
    grid-column: 3 / 6;
    grid-row: 1;
  }

  .navigationLinks{
    grid-column: 6 / 8;
    grid-row: 1;
  }

  .navigationUser{
    grid-column: 8 / 9;
    grid-row: 1;
    margin-right: 20px;
  }

  .links li{
    display: inline;
    margin: 5px;
  }

  #login{
    margin-top: 15px;
  }

  #mapModal{
    margin-top: 15px;
  }

  .navigationButton{
    height: 50px;
    font-weight: bolder;
    font-size: 1.5em;
    padding:10px;
    background: white;
    border-radius: 5px;
  }

  .policyLinks {
    margin-left: -50px;
    margin-top: -10px;
    margin-bottom: 10px;
    background: white;
    padding: 9px;
    z-index: 600;
    position: absolute;
    opacity:1;
    border-radius: 5px;
  }

}

@media only screen and (min-width: 913px) {

  .policyLinks {
    margin-left: -19px;
    margin-top: -10px;
    margin-bottom: 10px;
    background: white;
    padding: 10px;
    z-index: 600;
    position: absolute;
    opacity:1;
    border-radius: 5px;
  }

}

/*****NAVIGATION END*****/
