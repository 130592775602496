/***HEADLINES***/
.headlinesWrapper {
  word-break: break-all;
}

@media only screen and (max-width: 767px) {

  .headlinesWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    padding: 20px;
    margin-top: 125px;
  }

  .headlinesWrapper li{
    /*display: inline;*/
  }

  .newestPost {
    grid-column: 1 / 4;
    grid-row: 2;
  }

  .newestLocation {
    grid-column: 1 / 4;
    grid-row: 1;
  }

  .prolificPoster {
    grid-column: 1 / 4;
    grid-row: 4;
  }

  .historyHighlight{
    grid-column: 1 / 4;
    grid-row: 3;
  }

  .headlineFrame{
    width: 200px;
    height: 113px;
  }

  .newestPostItem {
    cursor: pointer;
  }

  .newestLocationItem {
    cursor: pointer;
  }

  .historyHighlightItem{
    cursor: pointer;
  }

}

@media only screen  and (min-width: 768px) and (max-width: 768px) {
  h2{
    font-size: 1.48em;
  }
}

@media only screen and (min-width: 768px) {

  .headlinesWrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    padding: 20px;
    margin-top: 150px;
  }

  .headlinesWrapper li{

  }
  .newestPost {
    grid-column: 1 / 5;
    grid-row: 1;
  }

  .newestLocation {
    grid-column: 5 / 9;
    grid-row: 1;
  }

  .prolificPoster {
    grid-column: 13 / 13;
    grid-row: 1;
  }

  .historyHighlight{
    grid-column: 9 / 12;
    grid-row: 1;
  }

  .newestPostItem {
    cursor: pointer;
  }

  .newestLocationItem {
    cursor: pointer;
  }

  .historyHighlightItem{
    cursor: pointer;
  }

  .headlineFrame{
    width: 150px;
    height: 84px;
  }

}

@media only screen and (min-width: 913px) {

  .headlineFrame{
    width: 250px;
    height: 141px;
  }

}

@media only screen and (min-width: 1280px) {

  .headlineFrame{
    width: 300px;
    height: 169px;
  }

}

/***HEADLINES END***/
