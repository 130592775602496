/***TRENDING***/
.trendingWrapper {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  padding: 20px;
  margin-top: 150px;
}

.trendingPosts li{
  display: inline;
  margin: 5px;
}

.trendingPostItem{
  cursor: pointer;
}

@media only screen and (max-width: 767px) {

  .trendingFrame{
    width:200px;
    height:113px;
  }

}

@media only screen and (min-width: 768px) {

  .trendingFrame{
    width:400px;
    height:225px;
  }

}

/***TRENDING END***/
